import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from "@apollo/client";
import { TOKEN } from "./queries";
import jwt_decode from "jwt-decode";

const terminalLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND}/graphql`,
  headers: {"keep-alive": "true"}, credentials: "include"
});

const authLink = new ApolloLink(async (operation, forward) => {
  const { cache } = operation.getContext();
  let token;
  try {
    const cacheValue = cache.readQuery({query: TOKEN});
    token = cacheValue.accessToken;
    if (!isTokenValid(token)) {
      console.log("Token expired - emergency refresh")
      token = await getNewToken();
      cache.writeQuery({
        query: TOKEN, data: {accessToken: token}
      });
    }
  } catch {
    token = null;
  }
  operation.setContext(({ headers }) => ({ headers: {
    authorization: token, ...headers
  }}));
  return forward(operation);
});

const link = ApolloLink.from([authLink, terminalLink]);

const cache = new InMemoryCache({

});

export const client = new ApolloClient({
  link: link, cache, credentials: "include",
  defaultOptions: {
    watchQuery: { fetchPolicy: "network-only" },
    query: { fetchPolicy: "network-only" },
    mutate: { fetchPolicy: "network-only" },
  },
})

const isTokenValid = token => {
  const payload = jwt_decode(token);
  const currentTimestamp = new Date().getTime() / 1000;
  return payload.expires - 60 > currentTimestamp;
}

const getNewToken = async () => {
  const resp = await fetch(`${process.env.REACT_APP_BACKEND}/graphql`, {
    method: "POST",
    credentials: "include",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({query: "{ accessToken }"})
  })
  const data = await resp.json();
  return data?.data?.accessToken || null;
}
