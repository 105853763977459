import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { TOKEN } from "../queries";
import { SIGNUP } from "../mutations";
import { UserContext } from "../contexts";
import { client } from "../api";
import Button from "./Button";
import { parseApolloError } from "../errors";

const SignupForm = () => {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [,setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const [signup, signupMutation] = useMutation(SIGNUP, {
    onCompleted: data => {
      setUser(data.signup.user);
      client.cache.writeQuery({
        query: TOKEN, data: {accessToken: data.signup.accessToken}
      });
      navigate("/");
    },
    onError: error => setErrors(parseApolloError(error))
  })


  const formSubmit = e => {
    e.preventDefault();
    setErrors(null);
    signup({variables: {password, name, email}});
  }

  const blockClass = "flex gap-2 mb-6 items-end w-full sm:mb-10";
  const labelClass = "w-14 block pb-4 text-right text-xs flex-shrink-0 sm:text-sm sm:w-16";
  const inputBlockClass = "w-full flex-grow min-w-0 flex flex-col gap-0.5";
  const inputClass = "border-2 border-gray-200 rounded w-full py-2.5 px-2.5 font-medium text-base sm:text-lg";
  const errorInputClass = "border-red-500 border-2 text-red-700";
  const errorTextClass = "left-16 text-red-100 text-xs -mt-3 sm:-mt-6";
  const loading = signupMutation.loading;

  return (
    <>
      <form
        className="mx-auto my-auto w-full h-full px-8 py-3 sm:w-full sm:max-w-md sm:h-auto"
        onSubmit={formSubmit}
      >
        <div className={blockClass}>
          <label className={labelClass} htmlFor="name">name</label>
          <div className={inputBlockClass}>
            {errors?.name && (<div className={errorTextClass}>{errors.name[0]}</div>)}
            <input
              id="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              className={`${inputClass} ${errors?.name ? errorInputClass : "border-white"}`}
              autoComplete="name"
              autoCapitalize="none"
              required
            />
          </div>
        </div>

        <div className={blockClass}>
          <label className={labelClass} htmlFor="email">email</label>
          <div className={inputBlockClass}>
            {errors?.email && (<div className={errorTextClass}>{errors.email[0]}</div>)}
            <input
              id="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className={`${inputClass} ${errors?.email ? errorInputClass : "border-white"}`}
              autoComplete="email"
              autoCapitalize="none"
              required
            />
          </div>
        </div>

        <div className={blockClass}>
          <label className={labelClass} htmlFor="password">password</label>
          <div className={inputBlockClass}>
            {errors?.password && (<div className={errorTextClass}>{errors.password[0]}</div>)}
            {errors?.token && (<div className={errorTextClass}>{errors.token[0]}</div>)}
            <input
              id="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className={`${inputClass} ${errors?.__all__ || errors?.password || errors?.token ? errorInputClass : "border-white"}`}
              autoComplete="new-password"
              autoCapitalize="none"
              required
            />
          </div>
        </div>

        <Button type="submit" className="border" loading={loading}>
          Sign Up
        </Button>

        <div className="flex justify-end mt-6 mb-2">
          <Link to="/login/">Log In</Link>
        </div>

      </form>
    </>
  );
};

SignupForm.propTypes = {

};

export default SignupForm;