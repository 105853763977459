import React from "react";
import { useQuery } from "@apollo/client";
import { COUNTERPARTY } from "../queries";
import { useParams } from "react-router";
import Amount from "../components/Amount";
import Base from "./Base";
import moment from "moment/moment";
import CounterpartyChart from "../components/CounterpartyChart";

const CounterpartyPage = () => {

  const { id } = useParams();

  const { data, loading } = useQuery(COUNTERPARTY, {
    variables: {id}
  });

  if (loading) return <Base loading={true} />

  const counterparty = data.counterparty;

  const sum = a => Math.abs(a.reduce((p, c) => p + c.amount, 0));

  const received = counterparty.transactions.filter(t => t.amount > 0 && !t.isLoan && !t.future);
  const sent = counterparty.transactions.filter(t => t.amount < 0 && !t.isLoan && !t.future);
  const borrowed = counterparty.transactions.filter(t => t.amount > 0 && t.isLoan && !t.future);
  const repaid = counterparty.transactions.filter(t => t.amount < 0 && t.isLoan && !t.future);
  const debt = sum(borrowed) - sum(repaid);
  const future = counterparty.transactions.filter(t => t.future);

  return (
    <Base>
      <h1 className="font-bold text-3xl mb-2">{counterparty.name}</h1>

      {received.length > 0 && (
        <div>You have received <Amount value={sum(received)} /> from {counterparty.name} </div>
      )}
      {sent.length > 0 && (
        <div>You have spent <Amount value={sum(sent)} /> on {counterparty.name}</div>
      )}
      {(borrowed.length > 0 || repaid.length > 0) && (
        <div className="mt-4">
          {debt > 0 && <div>You currently owe <Amount value={sum(borrowed)} /> to {counterparty.name}</div>}
          {debt < 0 && <div>You are currently owed <Amount value={sum(borrowed)} /> by {counterparty.name}</div>}
          {borrowed.length > 0 && (
            <div className="text-sm">You have borrowed <Amount value={sum(borrowed)} /> from {counterparty.name}</div>
          )}
          {repaid.length > 0 && (
            <div className="text-sm">You have repaid <Amount value={sum(repaid)} /> to {counterparty.name} </div>
          )}
        </div>
      )}
      {future.length && (
        <div>
          <div className="mb-2 mt-8">You have transactions coming up:</div>
          <table className="text-sm">
            {future.map(transaction => (
              <tr key={transaction.id}>
                <td className="pr-2"><Amount value={transaction.amount} /></td>
                <td>{moment([transaction.year, transaction.month - 1, 1]).format("MMMM Y")}</td>
                <td>{transaction.category.name}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
      <CounterpartyChart counterparty={counterparty} />
    </Base>
  );
};

CounterpartyPage.propTypes = {
  
};

export default CounterpartyPage;