import React from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";

const Base = props => {

  const { loading, children } = props;
  
  return (
    <Div100vh className="bg-gray-50">
      <Nav />
      <Sidebar />
      <main className={`ml-44 pl-4 pt-16 pr-4 pb-4 bg-gray-50 ${loading && "flex justify-center items-center h-full"}`}>
        {loading ? "Loading" : children}
      </main>
    </Div100vh>
  );
};

Base.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Base