import React from "react";
import PropTypes from "prop-types";

const DisplaySetter = props => {

  const {
    showNet, showBalance, showDebt, showFuture, showPast,
    setShowNet, setShowBalance, setShowDebt, setShowFuture, setShowPast
  } = props;

  const rowClass = "flex gap-4";
  const inputClass = "flex gap-1 cursor-pointer";
  const labelClass = "cursor-pointer";
  const checkboxClass = "cursor-pointer";

  return (
    <div className={`border rounded p-2 bg-white ${props.className || ""}`}>
      <div className={rowClass}>
        <div className={inputClass}>
          <label htmlFor="showDebt" className={labelClass}>Show Debt?</label>
          <input
            className={checkboxClass}
            type="checkbox"
            checked={showDebt}
            onChange={e => setShowDebt(e.target.checked)}
            id="showDebt"
          />
        </div>
        <div className={inputClass}>
          <label htmlFor="showBalance" className={labelClass}>Show Balance?</label>
          <input
            className={checkboxClass}
            type="checkbox"
            checked={showBalance}
            onChange={e => setShowBalance(e.target.checked)}
            id="showBalance"
          />
        </div>
        <div className={inputClass}>
          <label htmlFor="showNet" className={labelClass}>Show Net?</label>
          <input
            className={checkboxClass}
            type="checkbox"
            checked={showNet}
            onChange={e => setShowNet(e.target.checked)}
            id="showNet"
          />
        </div>
      </div>
      <div className={rowClass}>
        <div className={inputClass}>
          <label htmlFor="showPast" className={labelClass}>Show Past?</label>
          <input
            type="checkbox"
            className={checkboxClass}
            checked={showPast}
            onChange={e => setShowPast(e.target.checked)}
            id="showPast"
          />
        </div>
        <div className={inputClass}>
          <label htmlFor="showFuture" className={labelClass}>Show Future?</label>
          <input
            type="checkbox"
            className={checkboxClass}
            checked={showFuture}
            onChange={e => setShowFuture(e.target.checked)}
            id="showFuture"
          />
        </div>
      </div>
    </div>
  );
};

DisplaySetter.propTypes = {
  
};

export default DisplaySetter;