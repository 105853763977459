import gql from "graphql-tag";

export const USER_FIELDS = gql`
  fragment UserFields on UserType { 
    id email name initialBalance initialDebt
    scenarios { id name presumed }
  }`;

export const USER = gql`{
  me { 
    ...UserFields
  }
} ${USER_FIELDS}`;

export const MONTHS = gql`query user($scenarios: [ID]!) {
  me { 
    ...UserFields
    months(scenarios: $scenarios) { year month startBalance startDebt endBalance endDebt start end transactions {
      id amount counterparty { id name } isLoan future category { id name collapse }
    } }
  }
} ${USER_FIELDS}`;

export const COUNTERPARTY = gql`query counterparty($id: ID!) {
  counterparty(id:  $id) { id name transactions {
    id amount year month counterparty { id name } isLoan future category { id name collapse }
  } }
}`

export const TOKEN = gql`{ accessToken }`;