import React, { useContext } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { UserContext } from "../contexts";
import { LOGOUT } from "../mutations";
import { TOKEN } from "../queries";

const Nav = () => {

  const client = useApolloClient();

  const [,setUser] = useContext(UserContext);

  const [logout,] = useMutation(LOGOUT, {
    onCompleted: () => {
      setUser(false);
      client.cache.writeQuery({query: TOKEN, data: {accessToken: null}});
      client.cache.reset();
    }
  })

  return (
    <nav className="fixed top-0 left-0 right-0 ml-36 pl-4 pr-4 z-40 h-12 flex justify-end items-center bg-opacity-100">
      <div onClick={logout}>Log Out</div>
    </nav>
  );
};

Nav.propTypes = {
  
};

export default Nav;