import React from "react";
import { Link } from "react-router-dom";
import ScenarioSetter from "./ScenarioSetter";

const Sidebar = () => {

  return (
    <div className="w-44 h-screen fixed top-0 left-0 bg-gray-100">
      <Link to="/" className="flex h-12 items-center justify-center w-full center text-xl italic opacity-80 font-extrabold text-blue-900 hover:no-underline hover:opacity-100">
        Trajektory
      </Link>

      <ScenarioSetter className="mx-2 mt-12" />
    </div>
  );
};

Sidebar.propTypes = {
  
};

export default Sidebar;