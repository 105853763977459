import React from "react";
import LoginForm from "../components/LoginForm";

const LoginPage = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

LoginPage.propTypes = {
  
};

export default LoginPage;