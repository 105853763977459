import React, { useContext, useState } from "react";
import Nav from "../components/Nav";
import { UserContext } from "../contexts";
import MonthSummary from "../components/MonthSummary";
import RunwayChart from "../components/RunwayChart";
import DisplaySetter from "../components/DisplaySetter";
import Base from "./Base";
import { useQuery } from "@apollo/client";
import { MONTHS } from "../queries";

const HomePage = () => {

  const [user] = useContext(UserContext);

  const [months, setMonths] = useState(null);

  const [showFuture, setShowFuture] = useState(true);
  const [showPast, setShowPast] = useState(false);
  const [showNet, setShowNet] = useState(true);
  const [showBalance, setShowBalance] = useState(true);
  const [showDebt, setShowDebt] = useState(true);

  useQuery(MONTHS, {
    variables: {scenarios: user.scenarios.filter(s => s.active).map(s => s.id)},
    onCompleted: data => {
      setMonths(data.me.months);
    }
  })

  if (!months) return <Base loading={true} />

  const pastMonths = [];
  const futureMonths = [];
  let inPast = true;
  for (let month of months) {
    if (inPast && month.transactions.some(t => t.future)) {
      inPast = false;
    }
    if (inPast) {
      pastMonths.push(month)
    } else {
      futureMonths.push(month)
    }
  }

  return (
    <Base>
      <RunwayChart
        pastMonths={pastMonths} futureMonths={futureMonths}
        showPast={showPast} showFuture={showFuture}
        showBalance={showBalance} showNet={showNet} showDebt={showDebt}
      />

      <DisplaySetter
        showPast={showPast} showFuture={showFuture}
        showBalance={showBalance} showNet={showNet} showDebt={showDebt}
        setShowPast={setShowPast} setShowFuture={setShowFuture}
        setShowBalance={setShowBalance} setShowNet={setShowNet} setShowDebt={setShowDebt}
        className="mx-3 mb-8"
      />

      {showPast && (
        <div className="flex flex-col gap-4 px-3">
          {pastMonths.map((month, index) => (
            <MonthSummary month={month} key={index} />
          ))}
        </div>
      )}
      {showFuture && (
        <div className="flex flex-col gap-4 px-3">
          {futureMonths.map((month, index) => (
            <MonthSummary month={month} key={index} />
          ))}
        </div>
      )}
    </Base>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;