import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CounterpartyChart = props => {

  const { counterparty } = props;

  const x = [];
  const transactions = counterparty.transactions;
  const firstTransaction = transactions[0];
  const lastTransaction = transactions[transactions.length - 1];
  const firstMonth = [firstTransaction.year, firstTransaction.month];
  const lastMonth = [lastTransaction.year, lastTransaction.month];
  const months = [firstMonth];
  while (months[months.length - 1][0] !== lastMonth[0] || months[months.length - 1][1] !== lastMonth[1]) {
    if (months[months.length - 1][1] === 12) {
      months.push([months[months.length - 1][0] + 1, 1])
    } else {
      months.push([months[months.length - 1][0], months[months.length - 1][1] + 1])
    }
  }
  const data = months.reduce((p, c) => ({...p, [c]: [0, 0, 0, 0]}), {});
  for (let transaction of counterparty.transactions) {
    const index = transaction.isLoan ? transaction.amount > 0 ? 2 : 3 : transaction.amount > 0 ? 0 : 1;
    data[[transaction.year, transaction.month]][index] += transaction.amount
  }

  const series = [{
    data: Object.values(data).map(x => x[0])
  }, {
    data: Object.values(data).map(x => x[1])
  }, {
    data: Object.values(data).map(x => x[2])
  }, {
    data: Object.values(data).map(x => x[3])
  }]

  const options = {
    chart: {zoomType: "x", backgroundColor: null, type: "column"},
    plotOptions: {column: {
      stacking: "normal",
      pointPadding: 0,
      groupPadding: 0,
    }},
    title: null,
    legend: {enabled: false},
    credits: {enabled: false},
    yAxis: {title: {enabled: false}},
    series
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
};

CounterpartyChart.propTypes = {
  
};

export default CounterpartyChart;