import React from "react";
import SignupForm from "../components/SignupForm";

const SignupPage = () => {
  return (
    <div>
      <SignupForm />
    </div>
  );
};

SignupPage.propTypes = {
  
};

export default SignupPage;