import React, { useContext } from "react";
import { UserContext } from "../contexts";

const ScenarioSetter = props => {

  const [user, setUser] = useContext(UserContext);

  const toggleScenario = (id, active) => {
    setUser({...user, scenarios: user.scenarios.map(s => s.id === id ? {...s, active} : s)})
  }

  user.scenarios.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={`${props.className || ""}`}>
      {user.scenarios.map(scenario => (
        <div className="flex items-center text-xs gap-1" key={scenario.id}>
          <input
            id={`scenario${scenario.id}`}
            type="checkbox"
            className="cursor-pointer"
            checked={scenario.active}
            onChange={e => toggleScenario(scenario.id, e.target.checked)}
          />
          <label htmlFor={`scenario${scenario.id}`} className="cursor-pointer">{scenario.name}</label>
        </div>
      ))}
    </div>
  );
};

ScenarioSetter.propTypes = {
  
};

export default ScenarioSetter;