import React from "react";
import PropTypes from "prop-types";
import Amount from "../components/Amount";
import moment from "moment";
import TransactionList from "./TransactionList";

const MonthSummary = props => {

  const { month } = props;

  const income = month.transactions.filter(t => !t.isLoan && t.amount > 0);
  const loans = month.transactions.filter(t => t.isLoan && t.amount > 0);
  const expenditure = month.transactions.filter(t => !t.isLoan && t.amount < 0);
  const repayments = month.transactions.filter(t => t.isLoan && t.amount < 0);
  income.sort((a, b) => b.amount < a.amount ? -1 : 1);
  loans.sort((a, b) => b.amount < a.amount ? -1 : 1);
  expenditure.sort((a, b) => b.amount < a.amount ? 1 : -1);
  repayments.sort((a, b) => b.amount < a.amount ? 1 : -1);

  const partFuture = month.transactions.filter(t => t.future).length > 0 &&
    month.transactions.filter(t => !t.future).length > 0;

  return (
    <div className="border p-2 bg-white">
      <div className="font-medium">
        {moment([month.year, month.month - 1, 1]).format("MMMM Y")}
      </div>
      <div className="text-sm flex gap-5 mb-2">
        <div>
          <div>Start Balance: <Amount value={month.startBalance} /></div>
          <div>Start Debt: <Amount value={month.startDebt} /></div>
          <div className="font-medium">Start: <Amount value={month.start} /></div>
        </div>
        <div>
          <div>End Balance: <Amount value={month.endBalance} /></div>
          <div>End Debt: <Amount value={month.endDebt} /></div>
          <div className="font-medium">End: <Amount value={month.end} /></div>
        </div>
      </div>

      <div className="flex gap-8">
        <TransactionList transactions={month.transactions} incoming={true} loans={false} partFuture={partFuture} />
        <TransactionList transactions={month.transactions} incoming={true} loans={true} partFuture={partFuture} />
        <TransactionList transactions={month.transactions} incoming={false} loans={false} partFuture={partFuture} />
        <TransactionList transactions={month.transactions} incoming={false} loans={true} partFuture={partFuture} />
      </div>
    </div>
  );
};

MonthSummary.propTypes = {
  month: PropTypes.object.isRequired
};

export default MonthSummary;