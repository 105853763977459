import gql from "graphql-tag";
import { USER_FIELDS } from "./queries";

export const LOGIN = gql`mutation login($email: String! $password: String!) {
  login(email: $email password: $password) { accessToken user {
    ...UserFields
  } }
} ${USER_FIELDS}`;

export const LOGOUT = gql`mutation { logout { success } }`;

export const SIGNUP = gql`mutation login(
  $email: String! $password: String! $name: String!
) { signup(
  email: $email password: $password name: $name
) { accessToken user { 
  ...UserFields
 } }
} ${USER_FIELDS}`;