import React, { useState } from "react";
import PropTypes from "prop-types";
import Amount from "./Amount";
import { Link } from "react-router-dom";

const CategoryList = props => {

  const { category, partFuture } = props;

  const [collapsed, setCollapsed] = useState(null);

  const actualCollapsed = collapsed === null ? category.collapse : collapsed;

  const allFuture = !category.transactions.some(t => !t.future);

  return (
    <div className={allFuture && partFuture ? "text-gray-400" : ""}>
      <div className="font-medium">
        {category.name} <Amount value={category.sum} />{" "}
        <span 
          className="text-blue-600 text-2xs relative -top-1 font-normal cursor-pointer opacity-70 hover:underline"
          onClick={() => setCollapsed(!actualCollapsed)}
        >
          {actualCollapsed ? "Show" : "Hide"}
        </span>
      </div>
      {!actualCollapsed && (
        <div>
          {category.transactions.map(t => (
            <div className={`ml-2 ${t.future && partFuture ? "text-gray-400" : ""}`} key={t.id}>
              <Link to={`/counterparties/${t.counterparty.id}/`}>{t.counterparty.name}</Link> <Amount value={t.amount} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CategoryList.propTypes = {
  category: PropTypes.object.isRequired,
  partFuture: PropTypes.bool,
};

export default CategoryList;