import React, { useState, useEffect } from "react";
import { ApolloProvider, useQuery } from "@apollo/client";
import Div100vh from "react-div-100vh";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { PropagateLoader } from "react-spinners";
import { client } from "../api";
import { UserContext } from "../contexts";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import { TOKEN, USER } from "../queries";
import CounterpartyPage from "../pages/CounterpartyPage";

const App = () => {

  // Keep track of whether there is a logged in user
  const [user, setUser] = useState(null);

  // Get the current access token in the cache 
  const accessToken = client.cache.readQuery({query: TOKEN})?.accessToken;

  // Keep track of whether the current token is expired
  const [isExpired, setIsExpired] = useState(true);

  // Send request for access token if it is expired
  const tokenQuery = useQuery(TOKEN, {
    client,
    fetchPolicy: "network-only",
    skip: !isExpired,
    onCompleted: () => setIsExpired(false)
  });

  // If the token query has fired at least once, and log-in status is unknown,
  // try to get user
  useQuery(USER, {
    client,
    skip: tokenQuery.loading || user !== null,
    onCompleted: data => {
      setUser(data.me ? {
        ...data.me,
        scenarios: data.me.scenarios.map(
          s => ({...s, active: s.presumed})
        )} : false);
    }
  })

  useEffect(() => {
    // Whenever the access token changes, setup a new recurring function which
    // will check to see if it has expired or is getting old.

    const repeatingFunction = () => {
      if (accessToken && !isExpired) {
        const payload = jwt_decode(accessToken);
        const currentTimestamp = new Date().getTime() / 1000;
        const hasExpired = payload.expires < currentTimestamp;
        const validButOld = payload.iat + (60 * 3) < currentTimestamp;
        if (hasExpired) console.log("Token has expired, get a new one");
        if (validButOld) console.log("Token is getting old - refresh it");
        if (hasExpired || validButOld) setIsExpired(true);
      }
    }
    const intervalId = setInterval(repeatingFunction, 50);
    return () => clearInterval(intervalId);
  }, [accessToken, isExpired])

  // While log-in status is unknown, show loading page
  if (user === null) {
    return (
      <Div100vh className="flex items-center justify-center">
        <PropagateLoader color={"#4163DB"} />
      </Div100vh>
    )
  }

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={[user, setUser]}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={user ? <HomePage /> : <SignupPage />} />
            <Route path="/login/" element={<LoginPage />} />
            <Route path="/signup/" element={<SignupPage />} />
            {user && <Route path="/counterparties/:id" element={<CounterpartyPage />} />}
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </ApolloProvider>
  );
};

App.propTypes = {
  
};

export default App;