import React from "react";
import PropTypes from "prop-types";
import CategoryList from "./CategoryList";
import Amount from "./Amount";

const TransactionList = props => {

  const { transactions, incoming, loans, partFuture } = props;

  const relevantTransactions = transactions.filter(
    t => (incoming ? (t.amount > 0) : (t.amount < 0)) && (t.isLoan === loans)
  ).map(t => ({...t, amount: Math.abs(t.amount)}))
  const categoryIds = relevantTransactions.reduce(
    (p, c) => p.includes(c.category.id) ? p : [...p, c.category.id], []
  );
  const categories = categoryIds.map(id => {
    const transactions = relevantTransactions.filter(t => t.category.id === id);
    const category = transactions[0].category;
    return {
      id: id, name: category.name, collapse: category.collapse,
      transactions, sum: transactions.reduce((p, c) => p + c.amount, 0)
    }
  })
  categories.sort((a, b) => Math.abs(a.sum) < Math.abs(b.sum) ? 1 : -1);

  const sum = relevantTransactions.reduce((p, c) => p + c.amount, 0)

  if (relevantTransactions.length === 0) return null;

  return (
    <div>
      <div>
        <div className="text-sm font-bold">
          {incoming && !loans && "Income"}
          {incoming && loans && "Loans"}
          {!incoming && !loans && "Expenditure"}
          {!incoming && loans && "Repayments"}
        </div>
        <div className="text-xs font-bold"><Amount value={sum} /></div>
        <div className="text-xs">
          {categories.map(category => (
            <CategoryList category={category} key={category.id} partFuture={partFuture} />
          ))}
        </div>
      </div>
    </div>
  );
};

TransactionList.propTypes = {
  transactions: PropTypes.array.isRequired,
  incoming: PropTypes.bool.isRequired,
  loans: PropTypes.bool.isRequired,
  partFuture: PropTypes.bool
};

export default TransactionList;