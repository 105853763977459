import React from "react";
import PropTypes from "prop-types";
import { formatAmount } from "../utils";

const Amount = props => {

  const { value } = props;

  return (
    <span className={value < 0 ? "text-red-700" : ""}>{formatAmount(value)}</span>
  );
};

Amount.propTypes = {
  value: PropTypes.number.isRequired
};

export default Amount;