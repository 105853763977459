import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import colors from "tailwindcss/colors";
import { formatAmount } from "../utils";

const RunwayChart = props => {

  const { pastMonths, futureMonths, showFuture, showPast, showNet, showBalance, showDebt } = props;
  const allMonths = [...pastMonths, ...futureMonths];

  const makeSeries = (months, start) => {
    if (!months.length) return [[], [], []]
    const lastMonth = months[months.length - 1];
    const balance = months.map((m, index) => [index + start, m.startBalance]);
    balance.push([start + balance.length, lastMonth.endBalance]);
    const debt = months.map((m, index) => [index + start, m.startDebt]);
    debt.push([start + debt.length, lastMonth.endDebt]);
    const net = months.map((m, index) => [index + start, m.start]);
    net.push([start + net.length, lastMonth.end]);
    return [balance, debt, net];
  }

  const labelFormatter = ({pos}) => {
    const month = moment([allMonths[0].year, allMonths[0].month - 1]).add(pos, "months");
    return month.format("MMM YY");
  }

  function tooltipFormatter () {
    const month = moment([allMonths[0].year, allMonths[0].month - 1]).add(this.x, "months");
    const dateString = month.format("MMMM YYYY");
    const valueString = formatAmount(this.y);
    const topLine = `<div style="font-size:10px;">${dateString}</div>`;
    const bottomLine = `<div style="font-size:12px; margin-left:-20px;"><b>${this.series.name}</b>:${valueString}</div>`
    return `${topLine}<br>${bottomLine}`;
  }

  const [pastBalance, pastDebt, pastNet] = makeSeries(pastMonths, 0);
  const [futureBalance, futureDebt, futureNet] = makeSeries(futureMonths, pastMonths.length);

  const series = [];
  if (showPast && showBalance) {
    series.push({name: "Balance", data: pastBalance, marker: {radius: 0}, color: colors.green[700]})
  }
  if (showPast && showDebt) {
    series.push({name: "Debt", data: pastDebt, marker: {radius: 0}, color: colors.red[700]})
  }
  if (showPast && showNet) {
    series.push({name: "Overall", data: pastNet, marker: {radius: 0}, color: colors.gray[700]})
  }
  if (showFuture && showBalance) {
    series.push({name: "Balance", data: futureBalance, marker: {radius: 0}, color: colors.green[700], opacity: showPast ? 0.5 : 1})
  }
  if (showFuture && showDebt) {
    series.push({name: "Debt", data: futureDebt, marker: {radius: 0}, color: colors.red[700], opacity: showPast ? 0.5 : 1})
  }
  if (showFuture && showNet) {
    series.push({name: "Overall", data: futureNet, marker: {radius: 0}, color: colors.gray[700], opacity: showPast ? 0.5 : 1})
  }

  const options = {
    chart: {zoomType: "x", backgroundColor: null},
    title: null,
    legend: {enabled: false},
    credits: {enabled: false},
    tooltip: {formatter: tooltipFormatter},
    xAxis: {
      title: {enabled: false},
      tickInterval: 1,
      labels: {formatter: labelFormatter}
    },
    yAxis: {title: {enabled: false}},
    series
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
};

RunwayChart.propTypes = {
  pastMonths: PropTypes.array.isRequired,
  futureMonths: PropTypes.array.isRequired,
};

export default RunwayChart;